import Vue from 'vue';
// import router from './Router'
import axios from 'axios';


var that = Vue.prototype

const exceptedUrl = ['quote-price', 'coingecko'];

axios.defaults.baseURL = 'https://dex.coinrace.website/api'
// axios.defaults.baseURL = 'http://ba.test/api'


axios.interceptors.request.use(
    (config) => {
        let exist = exceptedUrl.findIndex(e => {
            if (config.url.indexOf(e) > -1) {
                return true;
            }
        })
        if (exist < 0) {
            that.state.loading = true
        }
        return config
    },
)

axios.interceptors.response.use(
    (res) => {
        that.state.loading = false
        if (res.config.url.includes(axios.defaults.baseURL)) {
            return res.data.data
        }
        return res.data
    },
    (err) => {
        that.state.loading = false
        const error = err.response || err.toJSON()

        if (error.data) {
            console.log(error.data?.message)

            return Promise.reject(error.data)
        }
    }
)



let func = async (config) => {
    try {
        const res = await axios(config)
        return [res, null]
    } catch (e) {
        return [null, e]
    }
}



const props = {
    get: async (url, config) => {
        try {
            const res = await axios.get(url, config)
            return [res, null]
        } catch (e) {
            return [null, e]
        }
    },
    post: async (url, data, config) => {
        try {
            const res = await axios.post(url, data, config)
            return [res, null]
        } catch (e) {
            return [null, e]
        }
    },
    put: async (url, data, config) => {
        try {
            const res = await axios.put(url, data, config)
            return [res, null]
        } catch (e) {
            return [null, e]
        }
    },
    delete: async (url, config={}) => {
        try {
            const res = await axios.delete(url, config)
            return [res, null]
        } catch (e) {
            return [null, e]
        }
    },
}



for (let prop in props) {
    func[prop] = props[prop];
}




that.$http = func;
that.$axios = axios

export const http = func
export default axios
