import Vue from 'vue'
import App from './App.vue'
import router from './Lib/Router';
import InlineSvg from 'vue-inline-svg';
import VueSweetalert2 from 'vue-sweetalert2'
import './Styles/Globalstyles.scss';
import './Styles/Animations.css';
import './Lib/State';
import './Lib/Axios'
import './Lib/GlobalLogics'

Vue.config.productionTip = false;
Vue.component('inline-svg', InlineSvg);

import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2, {
  toast : true,
  timerProgressBar : true,
  position: 'top-end',
  timer: 3000,
  showConfirmButton: false,
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
