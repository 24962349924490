export class Loop {
    // callback
    // name
    // time
    // isRun = false
    // isAlive = false
    sleep (ms) {
        return (new Promise(resolve => setTimeout(resolve, ms)))
    }

    constructor(callback, time = 5000, name = 'specific') {
        this.isRun = false
        this.isAlive = false
        this.callback = callback;
        this.time = time;
        this.name = name;
    }

    async run() {
        console.log(this.name + ' loop started')
        while (this.isRun) {
            this.isAlive = true
            await this.callback()
            await this.sleep(this.time)
        }
        this.isAlive = false
        console.log(this.name + ' loop stopped')
    }

    //setter
    stop() {
        console.log(this.name + ' loop stop')
        this.isRun = false
    }

    start() {
        console.log(this.name + ' loop start')
        this.isRun = true
        if (!this.isAlive) {
            this.run()
        } else {
            console.log(this.name + ' loop is alive')
        }
    }

    //methods
}
