<template>
  <div class="info-box">
    <div @click="showrate=true" v-if="!showrate" class="rate">
      <div class="title">
        <span>Rate</span>
        <inline-svg :class="{'rotate':showrate}" :src="require('../../assets/Icons/lightDropdown.svg')" alt=""/>
      </div>
      <div class="numbers">
        <div class="exchanges">
          <span>1</span>
          <span>{{ state.from.token.symbol }}</span>
          <span>=</span>
          <span>{{ $toLocal(parseFloat(state.from.amount) / parseFloat(state.to.amount), 4) }}</span>
          <span>{{ state.to.token.symbol }}</span>
          <span>(~${{ state.from.priceInDollar }})</span>
        </div>
        <inline-svg class="question-icon" :src="require('../../assets/Icons/question.svg')" alt=""/>
      </div>
    </div>
    <div class="rate-wrap" @click="showrate=false" v-if="showrate" >
      <div class="rate">
        <div class="title">
          <span>1 {{ state.from.token.symbol }} price</span>
          <inline-svg :class="{'rotate':showrate}" :src="require('../../assets/Icons/lightDropdown.svg')" alt=""/>
        </div>
        <div class="exchanges">
          <span>1</span>
          <span>{{ state.from.token.symbol }}</span>
          <span>=</span>
          <span>{{ $toLocal(parseFloat(state.from.amount) / parseFloat(state.to.amount), 4) }}</span>
          <span>{{ state.to.token.symbol }}</span>
          <span>(~${{ state.from.priceInDollar }})</span>
        </div>
      </div>
      <div class="rate">
        <div class="title">
          <span>1 {{ state.to.token.symbol }} price</span>
        </div>
        <div class="exchanges">
          <span>1</span>
          <span>{{ state.to.token.symbol }}</span>
          <span>=</span>
          <span>{{ $toLocal(parseFloat(state.to.amount) / parseFloat(state.from.amount), 4) }}</span>
          <span>{{ state.to.token.symbol }}</span>
          <span>(~${{ state.to.priceInDollar }})</span>
        </div>
      </div>
<!--      <div class="rate">-->
<!--        <span>Minimum Recived</span>-->
<!--        <div class="exchanges">-->
<!--          <span>15682129.4</span>-->
<!--          <span>{{ tocoinprice }}</span>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "TheInfoBox",
  data() {
    return {
      fromcoin: 'USDT',
      priceindollar: 1.01,
      tocoinprice: 0.9997,
      tocoin: 'DAI',
      showrate: false,
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.info-box {
  width: 100%;
}

.rate {
  font-family: 'poppins-light' !important;
  fill: var(--title-color);
  color: var(--title-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-weight: 100;
  bottom: -25px;
  font-size: clamp(10px, 1vw, 12px);

  &:nth-child(3) {
    bottom: -45px;
  }

  &:nth-child(4) {
    bottom: -65px;
  }

  .title {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
    cursor: pointer;
  }

  .numbers {
    .exchanges {
      display: flex;
      flex-direction: row;
      column-gap: 3px;
    }

    // .question-icon{
    //     fill: var(--title-color);
    // }
    display: flex;
    flex-direction: row;
    column-gap: 5px;
  }
}

@media only screen and(max-width: 600px) {
  .rate {
    display: none;
  }
}

.rate-wrap {

}

</style>
