import Vue from 'vue'
import Web3 from "web3";

const that = Vue.prototype


export default {


    async tron(swapData) {

        const {
            calldata,
            offsets,
            addresses,
            gasLimitsAndValues,
            inAmount,
            outAmount,
            minOutAmount,
            inToken,
            outToken
        } = swapData;

        const {tronWeb} = that.state.provider
        const contract = await tronWeb.contract().at(this.$state.chain.smart_contract_address)

        let swapParams = {
            feeLimit: 300000000,
        }
        if (inToken.toLowerCase() === "t9yd14nj9j7xab4dbgeix9h8unkkhxuwwb") {
            swapParams.callValue = inAmount;
        }
        let res = await contract.swap(
            inToken,
            outToken,
            parseInt(inAmount),
            parseInt(minOutAmount),
            parseInt(outAmount),
            '0x0000000000000000000000000000000000000000',
            addresses,
            calldata,
            offsets,
            gasLimitsAndValues
        ).send(
            swapParams
        )

        console.log(res)

    },

    async evm(swapData) {
        const {estimatedGas, data, gasPrice, value} = swapData;

        const web3 = new Web3(that.state.provider)

        const swapParams = {
            from: that.state.provider.selectedAddress,
            to: that.state.chain.smart_contract_address,
            gas: estimatedGas,
            gasPrice: gasPrice,
            value,
            data
        };

        try {
            const res = await web3.eth.sendTransaction(swapParams)
            console.log(res)
        } catch (e) {
            console.log(e)
        }

    }

}
