<template>
  <div class="home">
    <Confirmmodal v-if="confirmmodal" @closeConfirmModal="confirmmodal=false"/>
    <Banner @openConfirm="confirmmodal=true"/>
    <div class="home-body">
      <Aurora/>
      <Advantages/>
      <Statistics/>
      <Bigb/>
      <Slider/>
      <Coins/>
    </div>
  </div>
</template>

<script>
import Confirmmodal from '../Swap/Confirmmodal.vue'
import Advantages from './Advantages.vue';
import Aurora from './Aurora.vue';
import Banner from './Banner.vue'
import Bigb from './Bigb.vue';
import Coins from './Coins.vue';
import Slider from './Slider.vue';
import Statistics from './Statistics.vue';

export default {
  name: 'Homepage',
  components: {Banner, Confirmmodal, Aurora, Advantages, Statistics, Bigb, Slider, Coins},
  data() {
    return {
      confirmmodal: false,
    }
  },
  mounted() {
    this.state.theme = 'dark'
  },
}
</script>

<style lang="scss" scoped>
.home-body {
  z-index: 0;
  display: flex;
  flex-direction: column;
  row-gap: 100px;
  // height: 100vh;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    top: -200px;
    position: absolute;
    z-index: 0;
    background: url('../../assets/ribbon-about.png');
    background-repeat: no-repeat;
    background-size: 200% 100%;
    background-position: center;

  }
}

@media only screen and(max-width: 1000px) {
  .home-body {
    row-gap: 50px;
  }
}
</style>
