import Vue from 'vue';
import VueRouter from 'vue-router';
import Mainswap from '../components/Swap/Mainswap.vue';
import Homepage from '../components/Homepage/Homepage.vue'
import Market from '../components/Market/Market.vue';
import Faq from '../components/FAQ/Faq.vue';
import About from '../components/About/About.vue';

let that = Vue.prototype

Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: 'Homepage',
        component: Homepage
    },
    {
        path: '/swap',
        name: 'Swap',
        component: Mainswap
    },
    {
        path: '/swapsuggestion',
        name: 'Swapsuggestion',
        component: Mainswap
    },
    {
        path: '/market',
        name: 'Market',
        component: Market
    },
    {
        path: '/Faq',
        name: 'FAQ',
        component: Faq
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return new Promise(() => {
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                })
            }, 500)
        })
    }
})

router.beforeEach(async (to, from, next) => {
    that.state.loading = true
    //
    // if (!that.$pageOptions.social[0]) {
    //     try {
    //         that.$pageOptions.social = await that.$axios.get('/options/social')
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }

    next()
    that.state.loading  = false
    }
)

export default router
