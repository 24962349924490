<template>
  <div class="dropdown">
    <div class="drop-items" @click.stop="isOpen = !isOpen">

      <div v-if="!state[position].token.symbol && state.chain.symbol" class="coin-chooser">
        <span> Select Token </span>
      </div>

      <div v-if="state[position].token.symbol && state.chain.symbol" class="coin-chooser">
        <img class="image-round" :src=state[position].token.icon alt="">
        <span>{{ state[position].token.symbol }}</span>
      </div>

      <div v-if="position=='From' && state[position].token" class="max">
        <span class="max">MAX</span>
      </div>

      <inline-svg :class="{'dropdow-click':isOpen}"
                  :src="require('../../assets/Icons/lightDropdown.svg')"
                  class="svg-icon"/>

    </div>
    <div v-show="isOpen" class="choose" v-click-outside="close">
      <div class="search-bar">
        <input type="text" v-model="searchFilter" placeholder="Search">
        <inline-svg :src="require('../../assets/Icons/searchlight.svg')" class="search-icon" alt=""/>
      </div>

      <div class="coins"
           v-for="(coin,index) in filter"
           @click="selectToken(coin)"
           :key="index"
      >
        <img class="coin-img"
             :src=coin.icon
             alt=""
        >
        <span>{{ coin.symbol }}</span>
      </div>

    </div>
  </div>
</template>

<script>
import Balance from "@/Lib/Balance";
import Approve from "@/Lib/Approve";

export default {
  name: "TheSelectInput",
  props: ['position'],
  data() {
    return {
      isOpen: false,
      searchFilter: ''
    }
  },
  computed: {
    filter() {
      let filtered = this.state.allTokens
      if (this.state[this.position]['token']['symbol']) {
        filtered = filtered.filter(i => i.symbol !== this.state[this.position === 'to' ? 'from' : 'to']['token']['symbol'])
      }
      if (this.searchFilter) {
        filtered = filtered.filter(a => a.symbol.includes(this.searchFilter.toUpperCase()))
      }
      return filtered
    },
  },
  methods: {
    async selectToken(token) {
      if (this.position === 'from') {
        let needApprove = await this.needApprove(token)
        console.log('token', token)
        this.$emit('needApproveEvent', needApprove)
      }
      this.state[this.position]['token'] = token
      this.state[this.position]['balance'] = await this.getBalance(token)
      this.close()
    },
    close() {
      this.isOpen = false
      this.searchFilter = ''
    },
    async getBalance(token) {
      if (this.state.currentAddress === '') {
        return 0
      }
      let method = this.state.chain.compiler.toLowerCase() + 'Balance'
      return await Balance[method](this.state.currentAddress, token);
    },
    async needApprove(token) {
      if (this.state.currentAddress === '') {
        return false
      }
      let method = `${this.state.chain.compiler.toLowerCase()}CheckApprove`
      return await Approve[method](token)
    },
  },
  directives: {
    clickOutside: {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event)
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.choose {
  z-index: 21;
  left: 0;
  width: 100%;
  background: var(--background-home);
  box-shadow: 0px 0px 24px rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  color: var(--title-color);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  max-height: 240px;
  overflow: scroll;

  .coins {
    &:hover {
      color: var(--global-green);
    }

    transition: 1s;
    display: flex;
    flex-direction: row;
    padding: 8px;
    column-gap: 5px;
    font-weight: 500;
    font-size: clamp(11px, 1vw, 14px);

  }

  .search-bar {
    font-weight: 300;
    align-items: center;
    font-size: clamp(10px, 1vw, 13px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    svg {
      position: absolute;
      right: 8px;
      top: 8px;
      // background: var(--svg-color);
    }

  }
}

.max {
  background: var(--global-green);
  border-radius: 6px;
  padding: 0 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 800ms;

  &:hover {
    transform: scale(1.06, 1.03);
  }

  span {
    color: var(--ingreen-text) !important;
    font-weight: 100 !important;
    font-size: clamp(8px, 1vw, 10px) !important;
  }
}

.dropdown {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 200px;
  flex-direction: row;
  justify-content: space-between;
  background: var(--swap-items-background);
  border-radius: 8px;

  .drop-items {
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    align-items: center;
    justify-content: space-between;
    padding: 12px;

    span {
      font-weight: 700;
      font-size: clamp(14px, 1vw, 16px);
      color: var(--title-color)
    }

    img {
      height: 34px;
      width: 34px;
      border-radius: 50%;
    }
  }

  .coin-chooser {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
  }

}

</style>
