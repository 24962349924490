import Vue from 'vue'
import Web3 from "web3";

const that = Vue.prototype

export default {


    async tronApprove(from, amount) {
        console.log('that.state.chain.smart_contract_address tron', that.state.chain.smart_contract_address)

        const {tronWeb} = that.state.provider
        const contract = await tronWeb.contract().at(from.address)

        let a = await contract.approve(
            that.state.chain.smart_contract_address,
            tronWeb.toSun(amount)
        ).send()
        console.log('approve done')
        console.log(a)
    },

    async evmApprove(from, amount) {
        const web3 = new Web3(that.state.provider)
        const contract = new web3.eth.Contract(
            JSON.parse(from.abi),
            from.address
        )
        const approve = await contract.methods.approve(
            that.state.chain.smart_contract_address,
            Web3.utils.toWei(amount)
        ).send({
            // todo set
            from: that.state.provider.selectedAddress,
        })

        console.log(approve)
    },

    async tronCheckApprove(from) {
        if (from.address === that.state.chain.native_coin.address) {
            return false
        }
        console.log(' that.state.chain.native_coin.address',  that.state.chain.native_coin.address)
        console.log('from.address', from.address)

        const {tronWeb} = that.state.provider
        const contract = await tronWeb.contract().at(from.address)

        let a = await contract.allowance(
            that.state.currentAddress,
            that.state.chain.smart_contract_address
        ).call()

        return a._hex === '0x00'
    },

    async evmCheckApprove(from) {
        if (from.address === that.state.chain.native_coin.address) {
            return false
        }
        console.log('from', from)
        const web3 = new Web3(that.state.provider)
        const contract = new web3.eth.Contract(
            JSON.parse(from.abi),
            from.address
        )

        let a = (await contract
            .methods
            .allowance(
                (await web3.eth.getAccounts())[0],
                that.state.chain.smart_contract_address
            )
            .call())
        console.log('a approve', a)
        return a === '0'
    }

}
