<template>
  <div class="setting">
    <div class="setting-container">
      <div class="slippage-tolerance">
        <div class="subject">
          <div class="title">
            <inline-svg class="green-icon" :src="require('@/assets/Icons/graph.svg')" alt=""/>
            <p style="opacity:1">Slippage tolerance</p>
          </div>
          <!-- <img style="cursor:pointer" @click="slippage=!slippage" :class="{'swap-icon':slippage==false}" v-if="state.theme=='dark'" src="../../assets/Icons/darkDropdown.svg" alt=""> -->
          <inline-svg class="svg-icon" @click="slippage=!slippage"
                      :src="require('../../assets/Icons/lightDropdown.svg')" alt=""/>
        </div>
        <div v-if="slippage" class="tolerance">
          <div class="default-btn">
            <span @click="slippageChanged(0.5)">0.5%</span>
            <span @click="slippageChanged(1)">1%</span>
            <span @click="slippageChanged(3)">3%</span>
            <div
              :class="{'tolerancesituation1':state.settings.slippage == 0.5,'tolerancesituation2':state.settings.slippage==1,'tolerancesituation3':state.settings.slippage ==3}"
              class="tolerance-background"></div>
          </div>
          <input type="text" class="tolerance-input" placeholder="Custom" v-model="state.settings.slippage">
        </div>
        <div v-if="false" class="title">
          <inline-svg class="green-icon" :src="require('@/assets/Icons/clock.svg')" alt=""/>
          <p style="opacity:1">Slippage Deadline</p>
        </div>
        <input v-if="false" type="text" class="time-input" placeholder="Input">
      </div>
      <div class="slippage-tolerance" v-if="state.chain.symbol === 'ETH'">
        <div class="subject">
          <div class="title">
            <inline-svg class="green-icon" :src="require('@/assets/Icons/Gass.svg')" alt=""/>
            <p>Gas price</p>
            <span> ? </span>
          </div>
          <inline-svg class="svg-icon" :src="require('../../assets/Icons/lightDropdown.svg')" alt=""/>
        </div>
        <input type="text"
               class="time-input"
               placeholder="Input"
               v-model="state.settings.gasPrice"
        >
      </div>
      <div @click="state.liquiditymodal=true" class="setting-item" v-if="false">
        <div class="title">
          <inline-svg class="green-icon" :src="require('@/assets/Icons/Water.svg')" alt=""/>
          <p>Liquidity Sources</p>
          <span> ? </span>
        </div>
        <div class="continue">
          <p>{{ num }}</p>
          <inline-svg class="svg-icon" :src="require('../../assets/Icons/lightDropdown.svg')" alt=""/>
        </div>
      </div>
      <div class="setting-item" v-if="false">
        <div class="title">
          <inline-svg class="green-icon" :src="require('@/assets/Icons/Glass.svg')" alt=""/>
          <p>Partial fill</p>
          <span> ? </span>
        </div>
        <inline-svg class="svg-icon" :src="require('../../assets/Icons/lightDropdown.svg')" alt=""/>
      </div>
      <div class="setting-item" v-if="false">
        <p>Toggle Export mode</p>
        <div :class="{'btn-toggle':exportMode}" class="toggle" @click="exportMode=!exportMode">
          <div class="btn"></div>
        </div>
      </div>
      <div class="setting-item" v-if="false">
        <p>Disable multihops</p>
        <div :class="{'btn-toggle':multiHops}" class="toggle" @click="multiHops=!multiHops">
          <div class="btn"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Setting',
  data() {
    return {
      num: 34,
      tolerancesituation: 1,
      slippage: true,
      exportMode: false,
      multiHops: false,
    }
  },
  methods: {
    slippageChanged(e) {
      this.state.settings.slippage = e
    }
  }
}
</script>

<style lang="scss" scoped>
.tolerancesituation1 {
  left: 4px;

}

.tolerancesituation2 {
  left: 40%;

}

.tolerancesituation3 {
  left: 74%;

}

.tolerance-background {
  width: 25%;
  height: 85%;
  right: 0px;
  transition: 800ms;
  border-radius: 12px;
  position: absolute;
  background: var(--border-color);
  z-index: 1;

}

.tolerance {
  z-index: 5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.default-btn {
  background: transparent;
  overflow: hidden;
  position: relative;

  span {
    cursor: pointer;
    z-index: 3;
    padding: 12px 25px;
    color: var(--title-color);
    opacity: .6;
  }

  width: 70%;
  align-items: center;
  // padding: 4px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: var(--swap-background);
}

.tolerance-input {
  width: 25%;
  background: var(--swap-background);
  border-radius: 12px;

  &::placeholder {
    text-align: center;
  }
}

.time-input {
  width: 100%;
  background: var(--swap-background);
  border-radius: 12px;
  padding: 12px;

  &::placeholder {
    text-align: start;
  }
}

.subject {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.slippage-tolerance {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 8px;
  width: 100%;
  background: var(--swap-items-background);
  border-radius: 8px;
}

.btn-toggle {
  .btn {
    margin-left: 55%;
  }

  opacity: .6;
}

.toggle {
  width: 10%;
  border-radius: 6px;
  border: 1px solid var(--global-green);
  min-width: 55px;
  display: flex;
  padding: 3px;

  .btn {
    transition: 800ms;
    width: 45%;
    height: 20px;
    border-radius: 6px;
    background: var(--global-green);

  }
}

.continue {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 10px;
}

.title {
  display: flex;
  flex-direction: row;
  column-gap: 16px;

  span {
    color: var(--global-green);
  }
}

.setting {
  width: 100%;
  position: absolute;
  top: 10%;
}

.setting-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.setting-item {
  cursor: pointer;

  &:hover {
    p {
      opacity: 1 !important;
    }
  }

  display: flex;
  align-items: center;
  padding: 8px;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background: var(--swap-items-background);
  border-radius: 8px;
}

@media only screen and(max-width: 550px) {
  .default-btn {
    display: none;
  }
  .tolerance-input {
    width: 100%;
  }
}
</style>
