<template>
  <div class="faq">
    <div class="faq-modal-wrapper" v-if="isOpen" @click.self="isOpen = false">
      <div class="faq-modal">
        <div class="header">
          <div class="title">{{ faq.title }}</div>
          <div class="close" @click="isOpen = false">x</div>
        </div>
        <div class="content">
          <p>{{ faq.content }}</p>
        </div>
      </div>
    </div>
    <div class="faq-background">
      <div class="faq-entry">
        <div class="column"
             v-for="(ask,index) in faqs"
             :key="index"
        >
          <div class="item">
            <h4>Why Are you like this ?</h4>
            <p>Bibendum varius integer consequat quisque morbi amet, augue tincidunt. Lacus, gravida mauris, lacus nisl
              eget. Imperdiet tempor dictumst ac tellus elementum. Congue turpis amet massa, tempus rhoncus, vulputate
              in eleifend arcu. Sit sem sapien adipiscing et nunc auctor. Elit maecenas ac tellus fringilla lacinia est
              id squam.</p>
            <button class="more">
              <span>more</span>
              <inline-svg class="svg-icon" :src="require('../../assets/Icons/lightDropdown.svg')" alt=""/>
            </button>
          </div>
        </div>
      </div>
      <div class="title">
        <img src="../../assets/Icons/questionmark.svg" alt="">
        <h1>FAQ</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Faq',
  data() {
    return {
      faqs: ['', '', '',],
      faq: {title: '', content: ''},
      isOpen: false,
    }
  },
  mounted() {
    this.state.theme = 'dark'
  }
}
</script>

<style lang="scss" scoped>
.more {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  align-self: flex-end;
  justify-self: end;
  background: none;
  color: var(--title-color);

  &:hover {
    color: var(--global-green);

    .svg-icon {

      fill: var(--global-green);
    }
  }
}

.column {
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  min-width: 300px;

  .item {
    width: 95%;
    display: flex;
    border-radius: 12px;
    flex-direction: column;
    row-gap: 8px;
    padding: 16px;
    background: rgba(33, 51, 74, 0.27);
    backdrop-filter: blur(10px);
    box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.08);
  }
}

.faq-entry {
  height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 20%;
  color: var(--skin-color);
  font-weight: 700;
  font-size: clamp(36px, 3vw, 48px);
}

.faq-background {
  background: url('../../assets/ribbon.svg');
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: right;
  height: 100%;
  width: 100%;
  padding: 100px 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.faq {
  background: url('../../assets/colorball.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  height: 100%;
  width: 100%;
  position: relative;
}

.faq-modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.faq-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  max-width: 750px;
  left: calc(50% - 750px / 2);
  top: 50px;
  max-height: 80vh;
  background: rgba(33, 51, 74, 0.27);
  backdrop-filter: blur(10px);
  box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.08);
  border-radius: 14px;

  .header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;

    .title, .close {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: var(--title-color);
      display: block;
    }

    .close {
      cursor: pointer;
      font-size: 2em;
    }

  }

  .content {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    padding: 20px;
  }

}

@media only screen and(max-width: 1100px) {
  .title {
    display: none;
  }
  .column {
    width: 100%;
  }
}

@media only screen and(max-width: 600px) {
  .faq-entry {
    flex-direction: column;
  }
  .faq-background {
    padding: 100px 10px;
  }
}
</style>
