import Vue from "vue";
import Wallets from "@/Lib/Wallets";
import Approve from "@/Lib/Approve";
import Utils from "@/Lib/Utils";
import Web3 from "web3";


let that = Vue.prototype

that.$pageOptions = {
    social : [],
}

that.$starString = (arg) => {
    if ((typeof arg === 'string' || arg instanceof String) && arg.length > 12) {
        let first = arg.substr(0, 4)
        let second = arg.substr(-4, 4)
        return first + '****' + second
    }
    return arg
}

that.$getChains = async () => {
    const res = await that.$axios.get('/chains?status=ACTIVATED')
    that.state.chains = res

    let chainSymbol = localStorage.getItem('chain')

    if (!chainSymbol && chainSymbol === '') {
        chainSymbol = 'ETH'
    }


    const chain = res.find(i => i.symbol === chainSymbol)
    if (chain) {
        await that.$selectChain(chain)
        await that.$selectWallet(chain)
        let provider = localStorage.getItem('provider')
        if (provider) {
            let wallet = that.state.wallets.find(i => i.name === provider)
            await that.$triggerWallet(wallet)
        }
    }
}
that.$selectWallet = async (chain) => {
    that.state.wallets = await that.$axios.get(`chains/${chain.symbol}/wallets`)
}

that.$selectChain = async (chain) => {
    that.state.chain = chain;
    that.state.chainSelected = true;
    // await that.$selectWallet();
    await that.$getTokens(chain.symbol);
}

that.$exit = () => {
    that.state.chain = null;
    that.state.chainSelected = false;
    localStorage.chain = null;
    that.state.wallets = null;
}

that.$disconnect = () => {
    that.state.provider = null
    that.state.providerName = null
    that.state.currentAddress = ''
    that.state.selectedWallet = []
    that.state.from = {
        balance: 0,
        token: {},
        amount: 0,
        price: 0,
        priceInDollar:0
    }
    that.state.to = {
        balance: 0,
        token: {},
        amount: 0,
        price: 0,
        priceInDollar:0
    }
    localStorage.removeItem('chain')
    localStorage.removeItem('provider')
}

that.$getTokens = async (e) => {
    let res = await that.$axios.get(`chains/${e}/tokens`)
    that.state.allTokens = res
}

that.$triggerWallet = async (e) => {
    if (!that.state.selectedWallet.length) {
        that.$disconnect()
    }
    that.state.selectedWallet = e
    const chainId = that.state.chain.index

    localStorage.setItem('provider', e.name)
    localStorage.setItem('chain', that.state.chain.symbol)
    console.log('chain setted')

    that.state.providerName = e.name
    that.$refreshToken()
    if (!chainId) {
        that.state.provider = await Wallets[e.name]()
        return
    } else {
        that.state.provider = await Wallets[e.name](that.state.chain)
        console.log('that.state.provider', that.state.provider)
        that.state.currentAddress = await that.$getAddress()
    }
}

that.$getAddress = async () => {
    if (that.state.providerName === 'walletconnect') {
        return that.state.provider.accounts[0]
    }

    if (that.state.providerName === 'binancechainwallet') {
        let accounts = await that.state.provider.request({ method: 'eth_accounts' })
        return accounts[0]
    }

    if (that.state.providerName === 'coinbasewallet') {
        return that.state.provider._addresses[0]
    }

    return that.state.provider.selectedAddress

}

that.$isApproved = async (from) => {
    let method = `${that.state.chain.compiler.toLowerCase()}CheckApprove`
    let a = await Approve[method](from)
    return !a
}

// that.$getAllTokens = async () => {
//         let res = await that.$axios.get(`tokens`)
//         that.state.allTokens = res
//         console.log(that.state.allTokens);
// }

that.$toInt = (value) => {
    return Utils.toInt(value)
}

that.$alert = (message, error = true) => {
    that.$swal({
        icon: error ? 'error' : 'success',
        title: message,
        background: that.state.theme === 'dark' ? '#3166a3' : '#fff',
        color: that.state.theme === 'dark' ? '#fff' : '#000000',
        showClass: {
            popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
        }
    });
}

that.$refreshSwap = () => {
    that.state.refreshSwap++
}

that.$refreshToken = () => {
    that.state.refreshToken++
}

that.$detectOs = () => {
    let Name = "Unknown OS";
    if (navigator.userAgent.indexOf("Win") > -1) Name =
        "WINDOWS";
    if (navigator.userAgent.indexOf("Mac") > -1) Name =
        "MACINTOSH";
    if (navigator.userAgent.indexOf("Linux") > -1) Name =
        "LINUX";
    if (navigator.userAgent.indexOf("Android") > -1) Name =
        "ANDROID";
    if (navigator.userAgent.indexOf("like Mac") > -1) Name = "IOS";
    // console.log('Your OS: ' + name);
    return Name
}
that.$detectBrowser = () => {
    // Opera 8.0+
    let browserType
    let isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    if (isOpera) {
        browserType = 'OPERA'
    }

    // Firefox 1.0+
    let isFirefox = typeof InstallTrigger !== 'undefined';
    if (isFirefox) {
        browserType = 'FIREFOX'
    }

    // Safari 3.0+ "[object HTMLElementConstructor]"
    let isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
    })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    if (isSafari) {
        browserType = 'SAFARI'
    }
    // Internet Explorer 6-11
    // let isIE = /@cc_on!@/false || !!document.documentMode;
    // if (isIE) {
    //     browserType = 'IE'
    // }
    // Edge 20+
    let isEdge = !!window.StyleMedia;
    if (isEdge) {
        browserType = 'EDGE'
    }
    // Chrome 1 - 79
    let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if (isChrome) {
        browserType = 'CHROME'
    }
    // Edge (based on chromium) detection
    let isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
    if (isEdgeChromium) {
        browserType = 'EDGECHROMIUM'
    }
    // console.log('browserType : ' + browserType)
    return browserType
}
that.$detectDevice = () => {
    let deviceType
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        deviceType = 'TABLET'
    }
    if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua
        )
    ) {
        deviceType = 'MOBILE'
    } else {
        deviceType = 'DESKTOP'
    }
    // console.log(deviceType)
    return deviceType
}

that.$getGasPrice = async () => {
    const web3 = new Web3(that.state.provider)
    let a = await web3.eth.getGasPrice()
    return web3.utils.fromWei(a, 'Gwei')
}

that.$p2e = string => string.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))

that.$toLocal = (number, decimal = 8) => {
    //purify number
    number = number + ''
    if (number === '.') {
        if (decimal)
            return '0.'
        else
            return ''
    }

    if (number.startsWith('-'))
        return 0

    if (number.toLowerCase().includes('e')) {
        if (parseFloat(number) < 1.0e-6) {
            //zero numbers
            return 0
        } else
            number = parseFloat(number) + ''
    }


    number = that.$p2e(number).replace(/[^\d.]|\.{2}|,/ig, '')
    if (number.includes('.')) {
        number = number.split('.')[0] + '.' + (number.split('.')[1] || '')
    }

    if (parseFloat(number)) {
        //separate number
        const int = parseFloat(number.split('.')[0]).toLocaleString('en')
        if (number.includes('.') && decimal) {
            return int + '.' + (number.split('.')[1].slice(0, decimal) || '')
        } else {
            return int
        }
    } else {
        return number
    }

}


that.$getPriceFromCoinGecko = async () => {
    let tokens = []
    if (that.state.from.token.code) {
        tokens.push(that.state.from.token.code)
    }
    if (that.state.to.token.code) {
        tokens.push(that.state.to.token.code)
    }
    if (tokens.length) {
        let address = 'https://api.coingecko.com/api/v3/simple/price?vs_currencies=usd&ids=' + tokens.concat(',')
        let tokenPrices = await that.$axios.get(address)
        for (const prop in tokenPrices) {
            if (that.state.from.token.code === prop) {
                that.state.from.priceInDollar = tokenPrices[prop]['usd']
            }
            if (that.state.to.token.code === prop) {
                that.state.to.priceInDollar = tokenPrices[prop]['usd']
            }
        }
    }
}

that.$getTransactions = async () => {

}
