import Vue from 'vue';

Vue.use(require('vue-cookies'))

const that = Vue.prototype

const state = Vue.observable({
    selectedWallet: [],
    allTokens: [],
    wallets: [],


    username: '',
    theme: 'dark',
    walletmodal: false,
    liquiditymodal: false,
    chainSelected: false,


    chains: [],
    provider: null,
    providerName: null,
    currentAddress: '',
    chain: {},

    swapData: {},

    loop: {},
    loopStatus : true,
    priceInDollarLoop: {},
    priceInDollarLoopStatus : true,

    loading : false,


    refreshSwap : 0,
    refreshToken : 0,


    settings: {
        slippage : 1,
        gasPrice : 5
    },
    from : {
        balance : 0,
        token : {},
        amount : 0,
        price : 0,
        priceInDollar : 0
    },
    to : {
        balance : 0,
        token : {},
        amount : 0,
        price : 0,
        priceInDollar : 0
    },

})

that.state = state
export default state
