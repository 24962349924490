import Web3Modal from "web3modal";
import NetworkConstants from "@/Lib/Web3ModalConstants";
import providerOptions from "@/Lib/WalletProviderOptions";
import Web3 from "web3";
import Vue from "vue";

let that = Vue.prototype
// import {app} from '@/main'

export default {
    provider: null,

    async handleProviders(providers) {
        if (providers.providers && providers.providers.length) {
            providers.providers.forEach(
                i => {
                    if (i.isMetaMask) {
                        providers = i
                    }
                }
            )
        }

        this.startListen(providers)

        return providers
    },

    async changeNetwork(provider, currentChain) {
        console.log(provider)
        console.log(currentChain)
        try {
            await provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: Web3.utils.toHex(currentChain.index)}],
            });
        } catch (switchError) {
            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === 4902) {
                try {
                    await provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: Web3.utils.toHex(currentChain.index),
                                chainName: currentChain.symbol,
                                rpcUrls: [currentChain.rpc_url],
                            },
                        ],
                    });
                } catch (addError) {
                    // handle "add" error
                }
            }
            // handle other "switch" errors
        }
    },

    async metamaskProviderHandler(providers) {
        if (providers.providers && providers.providers.length) {
            providers.providers.forEach(
                i => {
                    if (i.isMetaMask) {
                        providers = i
                    }
                }
            )
        }

        this.startListen(providers)

        return providers
    },
    async binanceProviderHandler(providers) {
        if (providers.providers && providers.providers.length) {
            providers.providers.forEach(
                i => {
                    if (i.isMetaMask) {
                        providers = i
                    }
                }
            )
        }

        // this.startListen(providers)

        return providers
    },

    async metamask(currentChain) {
        if (typeof window.ethereum === 'undefined') {
            that.$alert('no MetaMask wallet')
        }

        const chainId = currentChain.index

        const web3Modal = new Web3Modal({
            network: NetworkConstants[chainId]['network'], // optional
            cacheProvider: false, // optional
            providerOptions: {} // required
        });

        const providers = await web3Modal.connect();
        const provider = await this.handleProviders(providers)

        if (!provider.selectedAddress) {
            that.$alert('active your metamask wallet')
            that.$disconnect()
            return
        }

        if (provider.chainId != Web3.utils.toHex(chainId)) {
            console.log('need to change network')
            await this.changeNetwork(provider, currentChain)
        }
        return provider
    },

    async brave(currentChain) {
        if (!window.ethereum || !window.ethereum.isBraveWallet) {
            that.$alert('no brave wallet')
            return false
        }
        const chainId = currentChain.index

        const web3Modal = new Web3Modal({
            network: NetworkConstants[chainId]['network'], // optional
            cacheProvider: false, // optional
            providerOptions: {} // required
        });

        const provider = await web3Modal.connect();


        if (provider.chainId != Web3.utils.toHex(chainId)) {
            console.log('need to change network')
            await this.changeNetwork(provider, currentChain)
        }

        return provider
    },

    async clvwallet(currentChain) {
        // if (!window.ethereum || !window.ethereum.isClvWallet) {
        //     that.$alert('no clover wallet')
        //     return false
        // }
        const chainId = currentChain.index

        const web3Modal = new Web3Modal({
            network: NetworkConstants[chainId]['network'], // optional
            cacheProvider: false, // optional
            providerOptions
        });
        console.log('web3Modal', web3Modal)

        const providers = await web3Modal.connectTo('clvwallet');
        console.log('providers', providers)
        const provider = await this.handleProviders(providers)

        if (provider.chainId != Web3.utils.toHex(chainId)) {
            console.log('need to change network')
            await this.changeNetwork(provider, currentChain)
        }

        return provider
    },

    async coinbasewallet(currentChain) {
        console.log('coinbasewallet')
        const chainId = currentChain.index

        const web3Modal = new Web3Modal({
            network: NetworkConstants[chainId]['network'], // optional
            cacheProvider: false, // optional
            providerOptions
        });

        console.log('web3Modal', web3Modal)

        const providers = await web3Modal.connectTo('coinbasewallet');
        console.log('providers', providers)
        const provider = await this.handleProviders(providers)

        if (!provider.isCoinbaseWallet) {
            alert('not coinbase')
            return
        }

        if (provider.chainId != Web3.utils.toHex(chainId)) {
            console.log('need to change network')
            await this.changeNetwork(provider, currentChain)
        }

        return provider
    },

    async walletconnect(currentChain) {
        const chainId = currentChain.index


        console.log('walletconnect')
        const web3Modal = new Web3Modal({
            network: NetworkConstants[chainId]['network'], // optional
            cacheProvider: false, // optional
            providerOptions // required
        });

        const provider = await web3Modal.connectTo('walletconnect');
        let web3 = new Web3(provider)
        console.log('web3', web3)
        console.log(provider)
        provider.enable()
        console.log(provider)
        return provider
    },

    async binancechainwallet(currentChain) {
        console.log('binancechainwallet')
        const chainId = currentChain.index
        const web3Modal = new Web3Modal({
            network: NetworkConstants[chainId]['network'], // optional
            cacheProvider: false, // optional
            providerOptions // required
        });

        const providers = await web3Modal.connectTo('binancechainwallet');
        console.log(providers)

        return this.binanceProviderHandler(providers)

    },

    async tronlink() {
        console.log('tronlink')

        const {tronLink, tronWeb} = window

        console.log(tronLink)
        if (!tronLink) {
            that.$alert('wallet does not exist.')
        }

        if (!tronLink.ready) {
            that.$alert('active your tronlink wallet')
        }

        console.log('tronLink', tronLink)
        console.log('tronWeb', tronWeb)

        try {
            const a = await tronLink.request({method: 'tron_requestAccounts'})
            console.log('tron request', a)
        } catch (e) {
            console.log(e)
        }

        this.handleTronWebEvents()
        //
        let obj = setInterval(async () => {
            if (tronWeb && tronWeb.defaultAddress.base58) {
                clearInterval(obj)
                that.state.currentAddress = tronWeb.defaultAddress.base58
            }
        }, 10)

        return tronLink
    },

    handleTronWebEvents() {
        window.addEventListener('message', (e) => {
            if (e.data.message && e.data.message.action == "tabReply") {
                if (e.data.message.data.data.node?.chain == '_') {
                    console.log("tronLink currently selects the main chain")
                } else {
                    console.log("tronLink currently selects the side chain")
                }
            }

            if (e.data.message && e.data.message.action == "setAccount") {
                console.log("setAccount event", e.data.message)
                console.log("current address:", e.data.message.data.address)

            }
            if (e.data.message && e.data.message.action == "setNode") {
                console.log("setNode event", e.data.message)
                if (e.data.message.data.node.chain == '_') {
                    console.log("tronLink currently selects the main chain")
                } else {
                    console.log("tronLink currently selects the side chain")
                }

                // Tronlink chrome v3.22.1 & Tronlink APP v4.3.4 started to support
                if (e.data.message && e.data.message.action == "connect") {
                    console.log("connect event", e.data.message.isTronLink)
                }

                // Tronlink chrome v3.22.1 & Tronlink APP v4.3.4 started to support
                if (e.data.message && e.data.message.action == "disconnect") {
                    console.log("disconnect event", e.data.message.isTronLink)
                }

                // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support
                if (e.data.message && e.data.message.action == "accountsChanged") {
                    console.log("accountsChanged event", e.data.message)
                    console.log("current address:", e.data.message.data.address)
                }

                // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support
                if (e.data.message && e.data.message.action == "connectWeb") {
                    console.log("connectWeb event", e.data.message)
                    console.log("current address:", e.data.message.data.address)
                }

                // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support
                if (e.data.message && e.data.message.action == "accountsChanged") {
                    console.log("accountsChanged event", e.data.message)
                }

                // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support
                if (e.data.message && e.data.message.action == "acceptWeb") {
                    console.log("acceptWeb event", e.data.message)
                }
                // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support
                if (e.data.message && e.data.message.action == "disconnectWeb") {
                    console.log("disconnectWeb event", e.data.message)
                }

                // Tronlink chrome v3.22.0 & Tronlink APP v4.3.4 started to support
                if (e.data.message && e.data.message.action == "rejectWeb") {
                    console.log("rejectWeb event", e.data.message)
                }

            }
        })
    },


    startListen(provider) {
        console.log('run listener')
        if (!provider._events["accountsChanged"]) {
            provider.on("accountsChanged", (accounts) => {
                console.log('accountsChanged', accounts);
            });
        }

// Subscribe to chainId change
        if (!provider._events["chainChanged"]) {
            provider.on("chainChanged", (chainId) => {
                console.log('chain changed', chainId)
                this.handleChainChanged(chainId)
            });
        }

// Subscribe to provider connection
        if (!provider._events["connect"]) {
            provider.on("connect", (info) => {
                console.log('connect', info);
            });
        }
// Subscribe to provider disconnection
        if (!provider._events["disconnect"]) {
            provider.on("disconnect", (error) => {
                console.log('disconnect', error);
            });
        }
    },

    handleChainChanged(chainId) {
        console.log('change chain by user ', that.state.chain.index)
        console.log(Web3.utils.toHex(that.state.chain.index))
        if (chainId !== Web3.utils.toHex(that.state.chain.index)) {

            const walletChain = that.state.chains.find(
                (i) => i.index == Web3.utils.hexToNumber(chainId)
            )

            console.log('walletChain', walletChain)

            that.state.chain = walletChain
            that.state.provider = this[that.state.providerName](walletChain)


        }
        console.log('chainChanged', chainId);
    },

}
