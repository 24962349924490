<template>
  <div class="navbar">
    <div class="component-container">

      <div class="btns">
        <img @click="$router.push({name: 'Homepage'})" src="../../assets/Icons/Logo.svg" alt="">
        <div class="navbar-items">
          <a @click.prevent="$router.push({name:'Swap'})" href="/swap">Swap</a>
          <a v-if="$route.name=='Homepage'" @click.prevent="" href="">Blog</a>
          <a @click.prevent="$router.push({name:'About'})" href="/about">About bestarex</a>
          <a @click.prevent="$router.push({name:'FAQ'})" href="/faq">FAQ</a>
        </div>
      </div>

<!-- main navbar -->

      <div class="dropdowns">
        <button v-if="state.provider" @click="dropdown=!dropdown" class="wallet">
          <div style="max-width: 200px; overflow-x: hidden; display: flex; gap: 20px" >
            <img style="width: 20px" :src="state.selectedWallet.logo" alt="">
            <span>{{ $starString(state.currentAddress) }}</span>
          </div>
          <inline-svg v-if="state.provider" class="svg-icon" :class="{'dropdow-click':dropdown}"
                      :src="require('../../assets/Icons/lightDropdown.svg')"/>
          <div v-if="dropdown" class="dropdown">
            <div class="dropdown-item">
              <inline-svg class="svg-icon" :src="require('../../assets/Icons/copy.svg')"/>
              <a @click.prevent="copyAddress">Copy Address</a>
            </div>
            <div @click="$router.push({name :'Market'})" class="dropdown-item">
              <inline-svg class="svg-icon" :src="require('../../assets/Icons/history.svg')"/>
              <a @click.prevent="$router.push({name :'Market'})">Transaction History</a>
            </div>
            <div class="dropdown-item">
              <inline-svg class="svg-icon" :src="require('../../assets/Icons/changewallet.svg')"/>
              <a @click.prevent="state.walletmodal = true">Change Wallet</a>
            </div>
            <div @click.prevent="Exit" class="dropdown-item">
              <inline-svg class="svg-icon" :src="require('../../assets/Icons/exit.svg')"/>
              <a @click.prevent="">Disconnect</a>
            </div>
          </div>
        </button>

        <button v-if="!state.provider" @click.self="connectWallet" class="wallet">Connect wallet</button>
        <div class="dropdown-back">
          <div class="dropdown-border">
            <div style="width:100%" @click="dropdownclick=!dropdownclick" class="dropdown-container">
              <div class="title">

                <!-- show selected Chain -->

                <img v-if="state.chain" :src="state.chain.logo" alt="">
                <span v-if="state.chain.symbol" class="chain-symbol">{{ state.chain.symbol }}</span>
                <span v-else> Chains </span>


              </div>
              <inline-svg class="svg-icon" :class="{'dropdow-click':dropdownclick}"
                          :src="require('../../assets/Icons/lightDropdown.svg')"/>
              <transition name="fade">
                <div v-if="dropdownclick" class="coins" :class="{'coins-swap':$route.name=='Swap'}">
                  <!-- Chains -->
                  <div @click="selectChain(coin)" v-for="(coin,index) in state.chains" :key="index" class="coin">
                    <img :src="coin.logo" alt="">
                    <span>{{ coin.symbol }}</span>
                  </div>


                </div>
              </transition>
            </div>
          </div>
        </div>
        <div v-if="$route.name!=='Homepage' && $route.name!=='FAQ' && $route.name!=='About'" class="theme-back">
          <div class="dropdown-border">
            <div class="theme-container">
              <div :class="{'go-left':state.theme=='light'}" class="pic enable">
                <transition name="fade">
                  <inline-svg
                    v-if="state.theme=='dark'"
                    fill="white"
                    :src="require('../../assets/Icons/Dark.svg')"
                  />
                  <inline-svg
                    v-if="state.theme=='light'"
                    :src="require('../../assets/Icons/light.svg')"
                  />
                </transition>
              </div>
              <div @click="changeTheme" :class="{'go-right':state.theme=='light'}" class="pic clicktheme">
                <transition name="fade">
                  <inline-svg
                    v-if="state.theme=='light'"
                    class="svg-icon"
                    :src="require('../../assets/Icons/Dark.svg')"
                  />
                  <inline-svg
                    v-if="state.theme=='dark'"
                    :src="require('../../assets/Icons/light.svg')"
                  />
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- main navbar -->

      <!--  side bar    -->
      <div @click="hambermenu=!hambermenu,dropdown=false" :class="{'hambermenu':hambermenu}" class="hamber-menu">
        <div class="first"></div>
        <div class="sec"></div>
        <div class="third"></div>
      </div>
      <transition name="sidebar">
        <div v-if="hambermenu" class="sidebar">
          <div class="sidebar-container">
            <a @click.prevent="hambermenu=false,$router.push({name:'Homepage'})" href="/">Home</a>
            <a @click.prevent="hambermenu=false,$router.push({name:'Swap'})" href="/swap">Swap</a>
            <a @click.prevent="hambermenu=false,$router.push({name:'FAQ'})" href="">Blog</a>
            <a @click.prevent="hambermenu=false,$router.push({name:'About'})" href="/about">About bestarex</a>
            <a @click.prevent="hambermenu=false,$router.push({name:'FAQ'})" href="">FAQ</a>
          </div>
        </div>
      </transition>
    </div>
    <!--  side bar    -->

  </div>
</template>

<script>

export default {
  name: 'Navbar',
  data() {
    return {
      dropdown: false,
      dropdownclick: false,
      hambermenu: false,
    }
  },
  computed: {},
  methods: {
    async selectChain(chain) {
      this.state.chainSelected = true;
      this.state.chain = chain
      this.$disconnect()
      this.$selectWallet(chain);
      await this.$getTokens(chain.symbol)
      this.$refreshSwap()
    },
    async getChains() {
      const res = await this.$axios.get('/chains')
      this.state.chains = res
    },
    changeTheme() {
      if (this.state.theme == 'dark') {
        this.state.theme = 'light'
      } else {
        this.state.theme = 'dark'
      }
    },
    async connectWallet() {
      if (this.$detectDevice() !== 'DESKTOP') {
        let provider = this.state.wallets.find(i => i.name === 'walletconnect')
        await this.$triggerWallet(provider)
        return
      }
      this.state.walletmodal = true;
    },
    Exit() {
      this.$disconnect()
      location.reload()
    },
    async copyAddress() {
      try {
        await navigator.clipboard.writeText(this.state.currentAddress)
        this.$alert('successfully copied.', false)
      } catch (e) {
        e
      }
    }
  },
  async mounted() {
    await this.$getChains()
  },
  created: function () {
    window.addEventListener("click", (event) => {
      if (!event.target.closest('.sidebar,.hamber-menu,.dropdowns')) {
        this.hambermenu = false
        this.dropdown = false
        this.dropdownclick = false
      } else {
        return true
      }

    })
  },
}
</script>

<style lang="scss" scoped>
.coins-swap {
  right: 100px !important;
}

.coins {
  max-height: 280px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: var(--background-home);
  position: absolute;
  top: 65px;
  right: 0px;
  width: 20%;
  box-shadow: 0px 0px 24px var(--shadow-color);

  .coin {
    padding: 8px;
    display: flex;
    color: var(--title-color);
    flex-direction: row;
    column-gap: 10px;
    transition: 500ms;

    span {
      transition: 500ms;
    }

    img {
      width: 24px;
      height: 24px;
    }

    &:hover {
      background: var(--item-hover);

      span {
        color: var(--global-green);
      }
    }
  }
}

.connected-wallet {
  max-width: 300px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  width: max-content;
}

.sidebar-wallet {
  padding: 10px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
}

.dropdown {
  display: flex;
  flex-direction: column;
  background: var(--background-home);
  position: absolute;
  width: 200px;
  top: 50px;
  right: 0;
  border-radius: 12px;
  overflow: hidden;

  .dropdown-item {
    align-items: center;
    color: var(--title-color);
    font-weight: 100;
    font-size: clamp(10px, 1vw, 12px);
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    padding: 12px;
    transition: 500ms;

    a {
      transition: 500ms;
    }

    a:hover {
      color: var(--global-green) !important;
    }

    &:hover {
      background: var(--item-hover);

      .svg-icon {
        fill: var(--global-green);
      }

    }
  }
}

.wallet {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  position: relative;
}

.svg-icon {
  fill: var(--svg-color);
  transition: 500ms;
}

.sidebar {
  z-index: 110;
  display: flex;
  align-items: center;
  background-size: cover;
  background: var(--sidebar-color);
  box-shadow: -8px 2px 8px var(--shadow-color);
  // padding: 30px 20px;
  height: 100vh;
  width: 60%;
  position: absolute;
  top: 0px;
  right: 0;

  .sidebar-container {
    a {
      text-align: center;
      width: 100%;
      padding: 10px;
      // height: 100%;
    }

    backdrop-filter: blur(200px);
    display: flex;
    padding: 60px 0;
    // justify-content: space-around;
    height: 100%;
    flex-direction: column;
    row-gap: 30px;
    width: 100%;
    align-items: center;
  }
}

.hambermenu {
  .first {
    transform: rotate(45deg) !important;
  }

  .third {
    display: none !important;
  }

  .sec {
    margin-top: -4.5px !important;
    transform: rotate(-45deg) !important;
  }
}

.hamber-menu {
  display: none;

  .first {
    transform: rotate(0deg);
    transition: 500ms;
  }

  .third {
    display: block;
  }

  .sec {
    transition: 500ms;
    margin-top: 0px;
    transform: rotate(0deg);
  }

  z-index: 112;
  width: 20px;
  position: absolute;
  right: 10px;
  justify-content: center;
  cursor: pointer;
  // display: flex;
  flex-direction: column;
  row-gap: 3px;
  padding: 1px;

  div {
    transform: 300ms;
    background: var(--border-color);
    width: 15px;
    height: 1.5px;
    transform: rotate(0deg);
    margin-top: 0px;
  }
}

.enable {
  right: 5%;
  transition: 1s;
  z-index: 1;
}

.clicktheme {
  right: 55%;
}

.theme-back {
  .theme-border {
    width: 100%;
    border: 1px solid;
    border-image-slice: 40%;
    border-image-source: linear-gradient(254.35deg, #0003BD -88.82%, #2DBDB5 115.62%);
  }

  transition: 1s;
  width: 20%;
  max-width: 95px;
  max-width: 95px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 0px 3px #2DBDB5 inset;
  border-left: 1px solid #2DBDB5;
  border-right: 1px solid #0003BD;

  &:hover {
    .dropdown-container {
      border-image-source: linear-gradient(90deg, #28CDC4 -5.82%, #0003BD 225.08%);
    }

    // box-shadow: 1px 1px 6px 0px #2dbdb5 inset;
    border-left: 1px solid #0003BD;
    border-right: 1px solid #28CDC4;

  }
}

.theme-container {
  position: relative;

  .pic {
    position: absolute;
    order: 1;
    padding: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 37px;
    height: 37px;
    transition: 1s;
  }

  max-width: 95px;
  height: 45px;
  width: 100%;
  display: flex;
  border-radius: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // border-image-source: linear-gradient(254.35deg, #0003BD -88.82%, #2DBDB5 115.62%);
}

.dropdowns {
  width: 80%;
  height: 70%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 20px;
  align-items: center;
  color: var(--title-color);
}

.btns {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 30px;
  width: 40%;

  img {
    cursor: pointer;
  }
}

.navbar-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  align-items: center;
}

.component-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 64px;
  width: 95%;
  column-gap: 20px;
  align-items: center;
  height: 100%;
}

.navbar {
  backdrop-filter: blur(20px);
  background: var(--navbar-background);
  max-height: 70px;
  width: 100%;
  top: 0px;
  position: fixed;
  z-index: 20;

}

.dropdown-back {
  width: 60%;
}

@media only screen and (max-width: 1300px) {

  .component-container {
    padding: 10px 20px;
  }
  .coins {
    width: 30%;
  }

}

@media only screen and (min-width: 1030px) {
  .sidebar {
    display: none;
  }
}

@media only screen and (max-width: 1030px) {
  .navbar-items {
    display: none;
  }
  .hamber-menu {
    display: flex;
  }
  .dropdowns {
    width: 75%;
  }
  .btns {
    width: 20%;
  }
}

@media only screen and(max-width: 800px) {
  .coins {
    width: 40%;
  }
}

@media only screen and (max-width: 650px) {
  .navbar-items {
    display: none;
  }
  .coins {
    width: 50%;
  }

  .sidebar {
    width: 60%;
  }
  .dropdowns {
    width: 90%;

  }

  .chain-symbol {
    display: none;
  }

  .dropdown-back {
    width: auto;
  }
}

@media only screen and(max-width: 500px) {
  .theme-back {
    width: 45%;
  }
  .dropdowns {
    justify-content: start;
  }
  .coins {
    width: 70%;
    right: unset;
    left: 0;
  }
}

@media only screen and(max-width: 400px) {


  .sidebar {
    width: 100%;
  }


}

// @supports not (backdrop-filter: blur()){
//     .navbar{
//         background: var(--support-navbar);

//     }
// }
@media only screen and(max-width: 350px) {
  .pic {
    width: 30px !important;
    height: 30px !important;
  }
}
</style>
