<template>
  <div :class="state.theme" id="app">
    <loading v-if="state.loading"/>
    <Connectwalletmodal @closeWalletModal="state.walletmodal=false" v-if="state.walletmodal" />
    <Liquidity v-if="state.liquiditymodal" @closeLiquidityModal="state.liquiditymodal=false" />
    <Navbar />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Connectwalletmodal from './components/Connectwalletmodal.vue'
import Liquidity from './components/Liquidity.vue'
import Footer from './components/Public/Footer.vue'
import Navbar from './components/Public/Navbar.vue'
import Loading from "@/components/Loading";

export default {
  components: { Navbar,Footer,Connectwalletmodal, Liquidity, Loading },
  name: 'App',
}
</script>

<style>
</style>
