import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

const INFURA_ID = "05aedf66803f21e67e972302878a9da5"
export default {
    binancechainwallet: {
        package: true
    },
    clvwallet: {
        package: true
    },
    walletconnect: {
        package: WalletConnectProvider, // required
        options: {
            infuraId: "05aedf66803f21e67e972302878a9da5", // required
            rpc: {
                1: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
                56: 'https://bsc-dataseed1.binance.org',
                137: 'https://rpc-mainnet.maticvigil.com',
                143114: 'https://api.avax.network/ext/bc/C/rpc',
                250: 'https://rpcapi.fantom.network',
                42161: 'https://arb1.arbitrum.io/rpc',
                100: 'https://rpc.xdaichain.com/',
                288: 'https://mainnet.boba.network',
                128: 'https://http-mainnet-node.huobichain.com',
                66: 'https://exchainrpc.okex.org',
                10: 'https://mainnet.optimism.io'
            },
            qrcodeModalOptions: {
                mobileLinks: [
                    "rainbow",
                    "metamask",
                    "argent",
                    "trust",
                    "imtoken",
                    "pillar",
                ],
            },
        }
    },
    coinbasewallet: {
        package: CoinbaseWalletSDK, // Required
        options: {
            appName: "My Awesome App", // Required
            infuraId: INFURA_ID, // Required
            darkMode: false // Optional. Use dark theme, defaults to false
        }
    },
}
