import Web3 from "web3";
import Vue from 'vue'

let that = Vue.prototype

export default {

    toInt(value) {
        let method = `${that.state.chain.compiler.toLowerCase()}ToInt`
        return this[method](value)
    },

    evmToInt(value) {
        const web3 = new Web3(that.state.provider)
        return web3.utils.fromWei(value)
    },

    tronToInt(value) {
        const {tronWeb} = that.state.provider
        return tronWeb.fromSun(value)
    }


}
