<template>
  <div :style="{ bottom: fromBottom + '%'}" class="exchange">

    <div class="states">


      <h4>{{ bottom }}</h4>
      <div class="combine-text">
        <p>balance</p>
        <span class="price"> {{ $toLocal(state.to.balance, 4) }}</span>
      </div>


    </div>


    <div class="states">
      <the-select-input position="to" @needApproveEvent="$emit('needApproveEvent', $event)"/>

      <input :disabled="bottom=='To'"
             :class="{'input-disable':bottom=='To'}"
             style="text-align:end"
             placeholder="0"
             class="price-inpt"
             :value="$toLocal(state.to.amount, 4)"
             @input="changeAmount($event.target.value)"
             type="text"
      >
    </div>


    <div class="states">
      <h4 style="opacity: .6;">{{ state.to?.token.symbol }}</h4>
      <p>${{ $toLocal(state.to.priceInDollar * state.to.amount) }}</p>
    </div>

  </div>
</template>

<script>
import Balance from '../../Lib/Balance.js';
import TheSelectInput from "@/components/Swap/TheSelectInput";

export default {
  name: 'BottomToken',
  components: {TheSelectInput},
  props: ['fromBottom', 'bottom', 'state.to'],
  data() {
    return {
      dropDownClick: false,
      searchFilter: '',
      Token: ''
    }
  },
  computed: {
    filter() {
      let filtered = this.state.allTokens
      if (this.state.from.token.symbol) {
        filtered = filtered.filter(i => i.symbol !== this.state.from.token.symbol)
      }
      if (this.searchFilter) {
        filtered = filtered.filter(a => a.symbol.includes(this.searchFilter.toUpperCase()))
      }
      return filtered
    },
  },
  methods: {
    drop() {
      if (this.state.chain) {
        this.dropDownClick = !this.dropDownClick

      } else {
        this.dropDownClick = false
      }

    },
    async getBalance() {
      if (this.state.currentAddress === '') {
        return 0
      }
      let token = this.Token
      let method = this.state.chain.compiler.toLowerCase() + 'Balance'
      return await Balance[method](this.state.currentAddress, token);
    },
    async selectToken(token) {
      this.Token = token
      // this.dropDownClick = false
      this.state.to.token = token
      this.state.to.balance = await this.getBalance()
    },
    changeAmount(e) {
      this.state.to.amount = e
    }
  },
  created: function () {
    window.addEventListener("click", event => {
      if (!event.target.closest('.dropdown')) {
        this.dropDownClick = false;
      }
    })
  }

}
</script>

<style lang="scss" scoped>
@import '../../Styles/SwapStyles.scss';
</style>
