<template>
  <div class="swap-wrapper">
    <div :class="{'home-back':$route.name=='Homepage'}" class="swap container">
      <div class="swap-container">

        <div class="header">
          <span class="title">{{ title }}</span>
          <inline-svg class="svg-icon" @click="changeSwap" v-if="setting==false"
                      :src="require('../../assets/Icons/darksetting.svg')" alt=""/>
          <inline-svg class="svg-icon" @click="changeSwap" v-if="setting==true"
                      :src="require('../../assets/Icons/lightDropdown.svg')" alt=""/>
        </div>

        <transition name="fade">
          <div :class="{'absolute':setting}" v-show="setting==false" class="body">

            <TopToken :key="'top' + state.refreshToken"
                      :fromTop.sync="fromtop"
                      :top.sync="top"
                      @needApproveEvent="needApprove = $event"
            />

            <inline-svg :class="{'rotate':fromtop!==0}" @click="changeDeal" class="change svg-icon"
                        :src="require('../../assets/Icons/Chage.svg')" alt=""/>

            <BottomToken :key="'bottom' + state.refreshToken"
                         :fromBottom="frombottom"
                         :bottom.sync="bottom"
                         @needApproveEvent="needApprove = $event"
            />


            <div class="swap-btn">
              <button v-if="!state.provider" @click="connectWallet" class="swap-btn">Connect Wallet</button>
              <button v-else-if="state.provider && hasAmount" class="swap-btn disabled">Insufficient balance</button>
              <button v-else-if="state.provider && needApprove" @click="approve" class="swap-btn">unlock
                {{ mainAmount }}
              </button>
              <button v-else-if="state.provider && !needApprove" @click="swap" class="swap-btn">swap</button>
            </div>
          </div>
        </transition>
        <transition name="fade">
          <Setting v-show="setting==true"/>
        </transition>
      </div>

    </div>
    <the-info-box v-if="state.from.token.symbol && state.to.token.symbol"/>
  </div>
</template>

<script>
import TopToken from './TopToken.vue';
import BottomToken from './BottomToken.vue';
import Setting from './Setting.vue';
import Approve from '@/Lib/Approve';
import Utils from "@/Lib/Utils";
import {Loop} from "@/Lib/Loop";
import TheInfoBox from "@/components/Swap/TheInfoBox";

export default {
  components: {
    TheInfoBox,
    Setting,
    TopToken,
    BottomToken,
  },
  name: 'Swap',
  data() {
    return {
      title: 'swap',
      setting: false,
      selectedToken: null,
      topdropdownclick: false,
      bottomdropdownclick: false,
      frombottom: 15,
      top: 'From',
      bottom: 'To',
      fromtop: 0,
      tokens: [],
      price: 0,
      coinprice: 0,
      finalprice: 0,
      topToken: null,
      bottomToken: null,
      searchFilterTop: '',
      searchFilterBottom: '',


      needApprove: false,

      loop: {}
    }
  },

  computed: {
    filterTop() {
      let filtered = this.state.allTokens
      if (this.searchFilterTop) {
        filtered = filtered.filter(a => a.symbol.includes(this.searchFilterTop.toUpperCase()))
      }
      return filtered
    },
    filterBottom() {
      let filtered = this.state.allTokens
      if (this.searchFilterBottom) {
        filtered = filtered.filter(a => a.symbol.includes(this.searchFilterBottom.toUpperCase()))
      }
      return filtered
    },
    mainAmount() {
      return this.top === 'From' ? this.state.from.amount : this.state.to.amount
    },
    hasAmount() {
      if (this.top === 'From') {
        return this.state.from.amount > this.state.from.balance
      } else {
        return this.state.to.amount > this.state.to.balance
      }
    }
  },
  methods: {
    async checkApprove() {
      if (this.state.currentAddress === '') {
        return false
      }
      let method = `${this.state.chain.compiler.toLowerCase()}CheckApprove`
      this.needApprove = await Approve[method](this.state.from.token)
    },

    async getQuotePrice() {
      if (!this.state.chain.symbol || !this.state.from.token.symbol || !this.state.to.token.symbol || !this.mainAmount) {
        return
      }

      let from = this.top === 'From' ? this.state.from : this.state.to
      let to = this.top === 'From' ? this.state.to : this.state.from

      const address = `/chains/${this.state.chain.symbol}/quote-price/${from.token.symbol}/${to.token.symbol}?amount=${from.amount}&gasPrice=${this.state.settings.gasPrice}&slippage=${this.state.settings.slippage}`

      let {inAmount, outAmount} = await this.$axios.get(address);
      inAmount
      to.amount = Utils.toInt(outAmount)
    },

    async approve() {
      let method = `${this.state.chain.compiler.toLowerCase()}Approve`
      let approve = await Approve[method](this.state.from.token, this.mainAmount)
      console.log('approve', approve)
    },

    async connectWallet() {
      if (this.$detectDevice() !== 'DESKTOP') {
        await this.$triggerWallet('walletconnect')
        return
      }
      this.state.walletmodal = true;
    },

    async changeDeal() {
      if (this.top == 'To') {
        this.top = 'From'
      } else {
        this.top = 'To'
      }
      if (this.bottom == 'To') {
        this.bottom = 'From'
      } else {
        this.bottom = 'To'
      }
      if (this.fromtop == 0) {
        this.fromtop = 50
        this.frombottom = 65
      } else {
        this.frombottom = 15
        this.fromtop = 0
      }
      await this.checkApprove()
    },
    async swap() {

      const swapData = await this.$axios.post(
        'chains/' + this.state.chain.symbol + '/swap/' + this.state.from.token.symbol + '/' + this.state.to.token.symbol,
        {
          amount: this.mainAmount,
          account: this.state.currentAddress
        }
      )
      this.state.swapData = swapData
      console.log('swapData', swapData)
      this.$emit('openConfirm', true);
    },
    changeSwap() {
      if (this.setting == false) {
        this.setting = true
        this.title = 'Setting'
      } else {
        this.setting = false
        this.title = 'swap'
      }
    },
    startLoops() {
      this.state.loop = new Loop(this.getQuotePrice, 5000, 'test')
      if (this.state.loopStatus) {
        this.state.loop.start()
      }

      this.state.priceInDollarLoop = new Loop(this.$getPriceFromCoinGecko, 10000, 'priceFromCoinGecko')
      if (this.state.priceInDollarLoopStatus) {
        this.state.priceInDollarLoop.start()
      }
    },
    stopLoops() {
      this.state.loop.stop()
      this.state.priceInDollarLoop.stop()
    }
  },
  mounted() {
    this.startLoops()
  },
  beforeDestroy() {
    this.stopLoops()
  },
  created: function () {
    window.addEventListener("click", (event) => {
      if (!event.target.closest('.dropdown')) {
        this.topdropdownclick = false;
        this.bottomdropdownclick = false;
      } else {
        return true
      }

    })
  },
}
</script>

<style lang="scss" scoped>

.swap-wrapper {
  width: 100%;
  max-width: 432px;
}

@import '../../Styles/SwapStyles.scss';
</style>

