<template>
  <div :style="{ top: fromTop + '%'}" class="exchange">


    <div class="states">
      <h4>{{ top }}</h4>
      <div class="combine-text">
        <p>balance</p>
        <span class="price"> {{ $toLocal(state.from.balance, 4) }}</span>
      </div>
    </div>

    <div class="states">
      <the-select-input position="from" @needApproveEvent="$emit('needApproveEvent', $event)"/>

      <input :class="{'input-disable':top=='To'}"
             :disabled="top=='To'"
             style="text-align:end"
             placeholder="0"
             class="price-inpt"
             :value="$toLocal(state.from.amount, 4)"
             @input="changeAmount($event.target.value)"
             type="text"
      >
    </div>


    <div class="states">
      <h4 style="opacity: .6;">{{ state.from?.token.symbol }}</h4>
      <p>${{ $toLocal(state.from.priceInDollar * state.from.amount) }}</p>
    </div>

  </div>
</template>

<script>
import Balance from '@/Lib/Balance.js';
import Approve from "@/Lib/Approve";
import TheSelectInput from "@/components/Swap/TheSelectInput";

export default {
  name: 'TopToken',
  components: {TheSelectInput},
  props: ['fromTop', 'top'],
  data() {
    return {
      DropDownClick: false,
      searchFilter: '',
      Token: '',
    }
  },
  computed: {
    filter() {
      let filtered = this.state.allTokens
      if (this.state.to.token.symbol) {
        filtered = filtered.filter(i => i.symbol !== this.state.to.token.symbol)
      }
      if (this.searchFilter) {
        filtered = filtered.filter(a => a.symbol.includes(this.searchFilter.toUpperCase()))
      }
      return filtered
    },
  },
  methods: {
    drop() {
      if (this.state.chain) {
        this.DropDownClick = !this.DropDownClick

      } else {
        this.DropDownClick = false
      }

    },
    async getBalance() {
      if (this.state.currentAddress === '') {
        return 0
      }
      let token = this.Token
      let method = this.state.chain.compiler.toLowerCase() + 'Balance'
      return await Balance[method](this.state.currentAddress, token);
    },
    async selectToken(token) {
      this.Token = token
      let arg = this.state.from
      arg.balance = await this.getBalance();
      let needApprove = await this.needApprove(token)
      console.log('need approove', needApprove)
      this.$emit('needApproveEvent', needApprove)
      this.state.from.token = token
      this.state.from.balance = await this.getBalance()
    },
    changeAmount(e) {
      this.state.from.amount = e
    },
    async needApprove(token) {
      if (this.state.currentAddress === '') {
        return false
      }
      let method = `${this.state.chain.compiler.toLowerCase()}CheckApprove`
      return await Approve[method](token)
    },
  },
  mounted() {

  },
  created: function () {
    window.addEventListener("click", event => {
      if (!event.target.closest('.dropdown')) {
        this.DropDownClick = false;
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '../../Styles/SwapStyles.scss';
</style>
