<template>
  <transition name="fade">
    <div @click.self="closeModale()" class="modal-backdrop">
      <div class="modal">
        <div class="content">
          <div class="header">
            {{ state.swap }}
            <span class="title">Confirm swap</span>
            <inline-svg class="swap-icon" @click="closeModale" :src="require('../../assets/Icons/closelight.svg')"
                        alt=""/>
          </div>
          <div class="coins">
            <div class="coin-container">
              <div class="img-container">
                <img :src="getIcon(state.swapData.inToken.symbol)" alt="">
                <span>{{ $toLocal($toInt(state.swapData.inAmount), 4) }}</span>
              </div>
              <span>{{ state.swapData.inToken.symbol }}</span>
            </div>
            <inline-svg class="change-icon green-icon" :src="require('../../assets/Icons/Chage.svg')" alt=""/>
            <div class="coin-container">
              <div class="img-container">
                <img :src="getIcon(state.swapData.outToken.symbol)" alt="">
                <span>{{ $toLocal($toInt(state.swapData.outAmount), 4) }}</span>
              </div>
              <span>{{ state.swapData.outToken.symbol }}</span>
            </div>
          </div>
          <div class="explain">
            <p>Output is Stimated.You Will receive at least
              <span class="fee">{{ state.swapData.outAmount }} {{ state.swapData.outToken.symbol }} </span> the
              transaction will revert.</p>
          </div>
          <div class="status">
            <div class="data">
              <span>Price</span>
              <div class="numbers">
                <span class="num">{{ $toLocal( (state.swapData.outAmount / state.swapData.inAmount) , 4) }}</span>
                <span class="name">{{ state.swapData.outToken.symbol }}/{{ state.swapData.inToken.symbol }}</span>
              </div>
            </div>
<!--            <div class="data">-->
<!--              <span>Price Impact ?</span>-->
<!--              <div class="numbers">-->
<!--                <span class="num">{{ coins[0].remain }}</span>-->
<!--                <span class="name">{{ coins[0].name }}</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="data">-->
<!--              <span>liquidity Provider fee ?</span>-->
<!--              <div class="numbers">-->
<!--                <span class="num">{{ parseInt(state.swapData.gasPrice) * state.swapData.estimatedGas }}</span>-->
<!--                <span class="name">{{ state.chain.native_coin.symbol }}</span>-->
<!--              </div>-->
<!--            </div>-->
          </div>
          <button @click="confirm">Confirm</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import Data from '../../Lib/Fakedata';
import Swap from "../../Lib/Swap";


export default {
  name: 'Confirmmodal',
  data() {
    return {
      counter: 0,
    }
  },
  methods: {
    getIcon(symbol) {
      let token = this.state.allTokens.find(i => i.symbol === symbol)
      return token.icon
    },
    closeModale() {
      this.$emit('closeConfirmModal', false)
    },
    async confirm() {
      // if (this.$route.name !== 'Swapsuggestion') {
      //   this.$router.push({name: 'Swapsuggestion'})
      //   this.closeModale();
      // } else
      // {
      await Swap[this.state.chain.compiler.toLowerCase()](this.state.swapData)
      await this.$router.push({name: 'Swap'})
      this.closeModale();
      // }
    }
  },
  mounted() {
    console.log('counter', this.counter++)
  }
}
</script>

<style lang="scss" scoped>
button {
  margin-top: 30px;
}

.status {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  color: var(--title-disable-color);

  .data {
    .numbers {
      .num {
        color: var(--title-color) !important;
      }

      display: flex;
      flex-direction: row;
      column-gap: 5px;
    }

    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.fee {
  font-weight: 700;
  font-size: clamp(14px, 2vw, 18px);
}

.img-container {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
}

.coins {
  display: flex;
  flex-direction: column;
}

.change-icon {
  align-self: center;
  justify-self: center;
}

.coin-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  span {
    color: var(--title-disable-color);
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  transition: 1s;

  .title {
    color: var(--global-green);

    span {
      font-weight: 700 !important;
      font-size: clamp(18px, 2vw, 22px) !important;
    }
  }
}

.swap-icon {
  cursor: pointer;
  transform: rotate(-90deg);
  max-width: 14px;
  max-height: 14px;
}

.content {
  width: 100%;
  min-height: 160px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  padding: 24px;
  background: url('../../assets/colorball.png');
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 12px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--modal-backdrop);
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  z-index: 110;
}

.modal {
  display: flex;
  flex-grow: 1;
  max-width: 430px;
  min-height: 160px;
  background: var(--background-home);
  border-radius: 12px;
  box-shadow: 0px 0px 24px var(--shadow-color);
  position: relative;
  // margin: 24px;
  box-sizing: border-box;
  // padding: 8px;
  // padding-bottom:24px ;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
