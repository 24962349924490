import Web3 from "web3";
import Vue from "vue";
let that = Vue.prototype
// import {app} from '@/main'


export default {

    async tronBalance(address, token) {
        const {tronWeb} = that.state.provider

        if (that.state.chain.native_coin && token.address === that.state.chain.native_coin.address) {
            let a = await tronWeb.trx.getBalance(address)
            return tronWeb.fromSun(a)
        }

        const contract = await tronWeb.contract().at(token.address)
        let a = await contract.balanceOf(
            address
        ).call()

        console.log('balance', tronWeb.fromSun(a))
    },

    async evmBalance(address, token) {
        const web3 = new Web3(that.state.provider)
        if (that.state.chain.native_coin && token.address === that.state.chain.native_coin.address) {
            let a = await web3.eth.getBalance(address)
            return web3.utils.fromWei(a)
        }

        const contract = new web3.eth.Contract(
            JSON.parse(token.abi),
            token.address
        )

        let a = await contract.methods.balanceOf(
            address
        ).call()
        return web3.utils.fromWei(a)
    },

}
